// bootstrap core
@import "~bootstrap/scss/functions";
// <- Include any default variable overrides here
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";

// bootstrap components
//@import "~bootstrap/scss/accordion";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/buttons";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/close";
@import "~bootstrap/scss/containers";
//@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/nav";
//@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/offcanvas";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/spinners";
//@import "~bootstrap/scss/tables";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/transitions";

// bootstrap flex grid
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";


// fontawesome
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';


// main layout
#wrapper {
  width: 100%;

  #menu {
    background-color: $cyan-800;

    .figure-caption {
      color: $white;
    }

    ul {
      li {
        a {
          display: block;
          padding: 0.5rem 1rem;
          text-decoration: none;
          color: $white;
          text-align: center;

        }
        @include media-breakpoint-up(md) {
          a {
            text-align: left;
          }
        }
        &:hover {
          background-color: $cyan-700;
        }
        &.active {
          background-color: $cyan-900;
        }
      }
    }

  }

  #content {
    width: 100%;
    max-width: 1200px;
    margin: auto;
  }
  @include media-breakpoint-up(md) {
    #content {
      min-height: 100vh;
    }
  }
}

.fa-heart {
  color: #de2717;
}


// helpers
ul.list-center {
  max-width: 800px;
}
